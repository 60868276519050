const validationNotRequired = ["store_filter","country_filter","create_package_check","create_collection_check","checkbox"];

export const packagename = async (name,code) =>{
    const regexTwo = /^[A-Za-z]{2}\s-/;
    const regexOne = /^[A-Za-z]{2}-/;
    
    if (name.match(regexTwo) || name.match(regexOne)) {
        let subStr = name.slice(0,2);;
        if(subStr.toLowerCase() !== code.toLowerCase()){
            name = code + '-'+ name;
        }
    }else{
        name = code + '-'+ name;
    }
    return name;
}

export const getDate=()=>{
    var date = new Date();
    return (((date.getMonth() > 8) ? (date.getMonth() + 1) : ('0' + (date.getMonth() + 1))) + '/' + ((date.getDate() > 9) ? date.getDate() : ('0' + date.getDate())) + '/' + date.getFullYear());
}

export const addDays = (date = new Date(),numOfDays) => {
    date.setDate(date.getDate() + numOfDays);
    return date;
}

export const filterProgram = async (formValues) =>{
    formValues.filter(function(b){
        delete b.is_new;
        delete b.index;
        return b;
    });
      return formValues;
}

export const showFormErrors = ()=>{
    var elements = "input,select,textarea";
    const inputs = document.querySelectorAll(elements);
    let isFormValid = true;

    inputs.forEach(input => {
        const name = input.name;
        const isCheckbox=(input.type==="checkbox");
        if(!validationNotRequired.includes(name)&& !isCheckbox){
            input.classList.add('active');
            input.closest('.input-group').classList.add('mb-3');

            const isInputValid = showInputError(input);

            if (!isInputValid) {
                isFormValid = false;
                input.closest('.input-group').classList.remove('mb-3');
            }
        }
    });

    return isFormValid;
}

const showInputError = (input)=>{
    // const name = input.name;
    const validity = input.validity;
    const label = input.closest('.input-group').previousSibling && input.closest('.input-group').previousSibling.textContent;
    const error = input.closest('.input-group').nextSibling === null?{}:input.closest('.input-group').nextSibling;
    if (!validity.valid) {
        if (validity.valueMissing) {
            error.textContent = `${label} is a required field`; 
        }else if (validity.typeMismatch) {
            error.textContent = `${label} should be a valid email address`; 
        }
        return false;
    }
    // if(input.name === 'operating_system' ){
    //     const searchWrap = document.querySelector('.myCustomPrefix__control');
    //     const operating_system_valid=input.value;
    //     if(!operating_system_valid){
    //         error.textContent = `${label} is a required field`;
    //         searchWrap.classList.add('invalid');
    //         return false;
    //     }else{
    //         searchWrap.classList.remove('invalid');
    //         searchWrap.classList.add('valid');
    //     }
    // }

    error.textContent = '';
    return true;
}

export const convertToUTC = (date)=>{
    return new Date(date).toUTCString();
}

export const globalNew =() =>{
    const currentHost = `${window.location.hostname}`;
    switch(currentHost) {
        case 'localhost':           //local
            return {
                CONST_CLIENTID:"d8e4a572-db77-4bdf-acb7-00fd70f095ec",
                CONST_AUTHORITY:"https://login.microsoftonline.com/5e985063-8eb2-4cf7-81c5-a5796b1f4db9",
                CONST_REDIRECTURI:"http://localhost:4200",
                API_HOST_LIVE:"https://rsmportalapi-dev.mcd.com/zucdrsmfunca02",
                SCOPES:['api://ceb8cf5e-8a6e-4c4f-92a3-07f09669e901/Invoke']
            }
        case 'rsmportal-dev.mcd.com':   //development
            return {
                CONST_CLIENTID:"d8e4a572-db77-4bdf-acb7-00fd70f095ec",// dev
                CONST_AUTHORITY:"https://login.microsoftonline.com/5e985063-8eb2-4cf7-81c5-a5796b1f4db9",
                CONST_REDIRECTURI:"https://rsmportal-dev.mcd.com",
                API_HOST_LIVE: 'https://rsmportalapi-dev.mcd.com/zucdrsmfunca02',
                SCOPES:['api://ceb8cf5e-8a6e-4c4f-92a3-07f09669e901/Invoke']
            }
        case 'rsmportal-uat.mcd.com': //UAT
            return {
                CONST_CLIENTID:"d8e4a572-db77-4bdf-acb7-00fd70f095ec",
                CONST_AUTHORITY:"https://login.microsoftonline.com/5e985063-8eb2-4cf7-81c5-a5796b1f4db9",
                CONST_REDIRECTURI:"https://rsmportal-uat.mcd.com",
                API_HOST_LIVE:"https://rsmportalapi-uat.mcd.com/zucnrsmasf01",
                SCOPES:['api://ceb8cf5e-8a6e-4c4f-92a3-07f09669e901/Invoke']
            }
        case 'rsmportal.mcd.com':       //production
            return {
                CONST_CLIENTID:"3b298495-7f5a-4399-88b3-a6c5e9d50758",
                CONST_AUTHORITY:"https://login.microsoftonline.com/c05b8d5a-b883-4afb-ae93-db5db239911c",
                CONST_REDIRECTURI:"https://rsmportal.mcd.com/",
                API_HOST_LIVE:"https://rsmportalapi.mcd.com/zucprsmfunca01",
                SCOPES:['api://2682f38d-4847-450a-b61e-6c481f986983/Invoke']
            }
        default:
            return {
                CONST_CLIENTID:"d8e4a572-db77-4bdf-acb7-00fd70f095ec",
                CONST_AUTHORITY:"https://login.microsoftonline.com/5e985063-8eb2-4cf7-81c5-a5796b1f4db9",
                CONST_REDIRECTURI:"http://localhost:4200",
                API_HOST_LIVE:"https://zucdrsmfunca02.azurewebsites.net/api",
                SCOPES:['api://ceb8cf5e-8a6e-4c4f-92a3-07f09669e901/Invoke']
            }
      }
}