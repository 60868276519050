import { lazy,Suspense } from 'react';
import { PublicRoute, ProtectedRoute } from './Myroutes';
import Login from '../components/auth/Login';
import Logout from '../components/auth/Logout';
import Navbar from '../common/Navbar';
import MaintainancePage from '../components/exceptions/MaintainancePage';


const Requestaccess = lazy(() => import('../components/Requestaccess'));
const ListRequests = lazy(() => import('../components/Requestaccess/Listrequests'));
const Removeaccessmain = lazy(() => import('../components/Requestaccess/RemoveAccess/index'));
const RefrenceTableList = lazy(() => import('../components/Admin/RefrenceTableList'));
const RefrenceTable = lazy(() => import('../components/Admin/RefrenceTable'));
const Createapplicationmain = lazy(() => import('../components/submitapplication/Createapplication'));
const ListApplication = lazy(() => import('../components/submitapplication/Listapplication'));
const SingleApplication = lazy(() => import('../components/submitapplication/Listapplication/Singleapplication'));
const Deployapplicationmain = lazy(() => import('../components/submitapplication/Deployapplication/index'));
const HealthStatus = lazy(() => import('../components/Clienthealth/HealthStatus'));
const DeviceHealthTable = lazy(() => import('../components/Clienthealth/HealthTable'));
const Dashboard = lazy(() => import('../components/dashboard/Dashboard'));
const Creatpackage = lazy(() => import('../components/submitrequest/Createpackage'));
const ListPackages = lazy(() => import('../components/submitrequest/Listpackages'));
const Deploypackagemain = lazy(() => import('../components/submitrequest/Deploypackage'));
const Singlepackage = lazy(() => import('../components/requesteditems/Singlepackage'));
const MarketCalendar = lazy(() => import('../components/market/MarketCalendar'));

const routesData = [
    {
        path: "/maintainance",
        component: <MaintainancePage />,
        role:"all"
    },
    {
        path: "/",
        component: <PublicRoute><Login /></PublicRoute>,
        role:"all"
    },
    {
        path: "/logout",
        component: <PublicRoute><Logout /></PublicRoute>,
        role:"all"
    },
    {
        path: "/dashboard",
        component: <ProtectedRoute><Navbar /></ProtectedRoute>,
        outlet:<Suspense fallback={<div>Loading...</div>}><Dashboard /></Suspense>,
        role:"all"
    },
    {
        path:"/create_package",
        component: <ProtectedRoute><Navbar/></ProtectedRoute>,
        outlet:<Suspense fallback={<div>Loading...</div>}><Creatpackage/></Suspense>,
        role:'admin'
    },
    {
        path:"/deploy_package",
        component: <ProtectedRoute><Navbar/></ProtectedRoute>,
        outlet:<Suspense fallback={<div>Loading...</div>}><Deploypackagemain/></Suspense>,
        role:"admin"
    },
    {
        path:"/request_access",
        component: <ProtectedRoute><Navbar/></ProtectedRoute>,
        outlet:<Suspense fallback={<div>Loading...</div>}><Requestaccess/></Suspense>,
        role:"all"
    },
    // {
    //     path:"/remove_access",
    //     component: <ProtectedRoute><Navbar/></ProtectedRoute>,
    //     outlet:<Suspense fallback={<div>Loading...</div>}><Removeaccessmain/></Suspense>,
    //     role:"all"
    // },
    {
        path: "/package/:id",
        component:<ProtectedRoute><Navbar /></ProtectedRoute>,
        outlet: <Suspense fallback={<div>Loading...</div>}><Singlepackage /></Suspense>,
        role:"all"
    },
    {
        path: "/application/:id",
        component:<ProtectedRoute><Navbar /></ProtectedRoute>,
        outlet: <Suspense fallback={<div>Loading...</div>}><SingleApplication /></Suspense>,
        role:"all"
    },
    {
        path: "/access/:id",
        component:<ProtectedRoute><Navbar /></ProtectedRoute>,
        outlet: <Suspense fallback={<div>Loading...</div>}><Singlepackage /></Suspense>,
        role:"all"
    },
    {
        path: "/modify_reference_table",
        component:<ProtectedRoute><Navbar /></ProtectedRoute>,
        outlet: <Suspense fallback={<div>Loading...</div>}><RefrenceTableList/></Suspense>,
        role:"superadmin"
    },
    {
        path: "/edit_reference_table",
        component:<ProtectedRoute><Navbar /></ProtectedRoute>,
        outlet: <Suspense fallback={<div>Loading...</div>}><RefrenceTable/></Suspense>,
        role:"superadmin"
    },
    {
        path: "/edit_reference_table/:id",
        component:<ProtectedRoute><Navbar /></ProtectedRoute>,
        outlet: <Suspense fallback={<div>Loading...</div>}><RefrenceTable/></Suspense>,
        role:"superadmin"
    },
    {
        path:"/list_package",
        component: <ProtectedRoute><Navbar/></ProtectedRoute>,
        outlet:<Suspense fallback={<div>Loading...</div>}><ListPackages/></Suspense>,
        role:"admin"
    },
    {
        path:"/list_access",
        component: <ProtectedRoute><Navbar/></ProtectedRoute>,
        outlet:<Suspense fallback={<div>Loading...</div>}><ListRequests/></Suspense>,
        role:"all"
    },
    {
        path:"/list_application",
        component: <ProtectedRoute><Navbar/></ProtectedRoute>,
        outlet:<Suspense fallback={<div>Loading...</div>}><ListApplication route="list_application" /></Suspense>,
        role:"admin"
    },
    {
        path: "/list_package/:id",
        component:<ProtectedRoute><Navbar /></ProtectedRoute>,
        outlet: <Suspense fallback={<div>Loading...</div>}><Singlepackage /></Suspense>,
        role:"all"
    },
    {
        path: "/list_application/:id",
        component:<ProtectedRoute><Navbar /></ProtectedRoute>,
        outlet: <Suspense fallback={<div>Loading...</div>}><SingleApplication /></Suspense>,
        role:"all"
    },
    {
        path: "/list_access/:id",
        component:<ProtectedRoute><Navbar /></ProtectedRoute>,
        outlet: <Suspense fallback={<div>Loading...</div>}><Singlepackage /></Suspense>,
        role:"all"
    },
    {
        path: "/create_application",
        component: <ProtectedRoute><Navbar/></ProtectedRoute>,
        outlet:<Suspense fallback={<div>Loading...</div>}><Createapplicationmain/></Suspense>,
        role:'admin'
    },
    {
        path: "/deploy_application",
        component: <ProtectedRoute><Navbar/></ProtectedRoute>,
        outlet:<Suspense fallback={<div>Loading...</div>}><Deployapplicationmain/></Suspense>,
        role:'admin'
    },
    // {
    //     path: "/client_health",
    //     component: <ProtectedRoute><Navbar/></ProtectedRoute>,
    //     outlet:<Suspense fallback={<div>Loading...</div>}><HealthStatus/></Suspense>,
    //     role:'admin'
    // },
    // {
    //     path: "/device_health_table",
    //     component: <ProtectedRoute><Navbar/></ProtectedRoute>,
    //     outlet:<DeviceHealthTable/>,
    //     role:'admin'
    // },
    // {
    //     path: "/market_calendar",
    //     component: <ProtectedRoute><Navbar/></ProtectedRoute>,
    //     outlet:<MarketCalendar/>,
    //     role:'admin'
    // }
    

]

export default routesData;