import Logo from '../../assets/img/logo.png';
import { useAuthProvider } from '../../config/authProvider';
const Login = () =>{
  const {handleLogin} = useAuthProvider()

  return (
    <>
      <div className="container-fluid login-wrapper">
          <div className="login-box">
              <div className="text-center mb-4">
                <img src={Logo} alt="McDonalds Logo" width="32" height="28"/> 
                <span style={{paddingLeft: "19px",textTransform: "uppercase",fontSize: "22px"}}>RSM Portal</span>
                    </div>
                       <div className="text-center mb-4">
                           <div className="bsk-container text-center mb-3">
                          <button className="login-button" onClick={() => handleLogin("redirect")}>
                          <object type="image/svg+xml" data="https://s3-eu-west-1.amazonaws.com/cdn-testing.web.bas.ac.uk/scratch/bas-style-kit/ms-pictogram/ms-pictogram.svg" className="x-icon" alt="microsoft-icon" aria-labelledby='microsoft-icon'></object> 
                          Sign in with Microsoft</button>
                      </div>
                    </div>
                </div>
            </div>
        </>
  );
}

export default Login;