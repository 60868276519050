import React from 'react'

import maintainencePage from "../../assets/img/maintainencePage.jpg"
import Logo from '../../assets/img/logo.png'

const MaintainancePage = () => {
  return (
    <>
      <div className="container-fluid">
        <div className="row maintainance-menu shadow-sm shadow-lg">
          <div className="col-sm-2 pl-0 text-center header-logo">
            <div className="bg-theme mr-3 pt-3 pb-2 mb-0">
              <h3 className="logo">
                <img src={Logo} alt="McDonalds Logo" className="logo w-100 h-100" style={{ maxHeight: "28px", maxWidth: "32px" }} width="32" height="28" />
              </h3>
            </div>
          </div>
          <div className='maintainance-menu-bar'>
          </div>
        </div>
        <div className="row">
          <div className='col-sm-12 col-xs-12 content pt-3 pl-0 d-flex justify-content-center align-items-center error_page_wrapper'>
            <img src={maintainencePage} className="maintainance-page" alt="maintainence" />
          </div>
        </div>
      </div>

    </>

  )
}

export default MaintainancePage