import clsx from "clsx";
import PropTypes from 'prop-types';
import styles from './Button.module.css';

/**
 * @function ButtonOnClick
 * @param {Event} e
 */

/**
 * @typedef ButtonProps
 * @property {React.ReactNode} children
 * @property {('button' | 'submit' | 'reset')=} type
 * @property {('default' | 'outline')=} btnStyle
 * @property {('normal' | 'wide')=} size
 * @property {string=} className
 * @property {string=} name
 * @property {string=} title
 * @property {boolean=} disabled
 * @property {React.MouseEventHandler<HTMLButtonElement>} onClick
 */

/**
 * @param {ButtonProps} props 
 */
const Button = ({ 
  children, 
  className, 
  name,
  title, 
  type = 'button', 
  btnStyle = 'default',
  size = 'normal',
  disabled, 
  onClick,
  ...otherProps 
}) => {
  return (
    <button 
      title={title || children.toString()} 
      type={type} 
      name={name}
      disabled={disabled}
      className={clsx( styles.btn, className, styles[`style-${btnStyle}`], styles[`size-${size}`] )} 
      onClick={onClick}
      {...otherProps}>
      {children}
    </button>
  )
}


Button.propTypes = {
  children: PropTypes.node.isRequired,
  type: PropTypes.oneOf(['button', 'submit', 'reset']),
  btnStyle: PropTypes.oneOf(['default', 'outline']),
  size: PropTypes.oneOf(['normal', 'wide']),
  className: PropTypes.string,
  title: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
}

export default Button;