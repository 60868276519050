// import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import Logo from '../../assets/img/logo.png';
// import AuthService from '../services/Auth.service';
const Logout = () =>{
// useEffect(()=>{
//   AuthService.logout()
// },[])
  return (
    <>
      <div className="container-fluid login-wrapper">
          <div className="login-box">
              <div className="text-center mb-4">
                <img src={Logo} alt="McDonalds Logo" width="32" height="28"/> 
                <span style={{paddingLeft: "19px",textTransform: "uppercase",fontSize: "22px"}}>RSM Portal</span>
              </div>
              <div className="text-center mb-4">
                  You are succesfully logged out!
                  <div>
                  <Link to='/' style={{color:"dodgerblue"}}>Click here to login again</Link>
                  </div>
              </div>
          </div>
      </div>
    </>
  );
}

export default Logout;